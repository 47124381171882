import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing } from '@tokens'

export const TeaserFooter = styled.div`
  margin-top: auto;
  margin-bottom: 0;
  position: relative;
  width: 100%;
`

export default ({ className, children, type }) => {
  return (
    <TeaserFooter className={className} type={type}>
      {children}
    </TeaserFooter>
  )
}
