import React, { useState, useCallback } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { spacing, media, box, tokens } from '@tokens'
import ComponentContainer from '@layout/ComponentContainer'
import Teaser from '@components/Teasers/'
import ComponentContent from '@blocks/ComponentContent'
import TextContent from '@elements/TextContent'
import Heading from '@elements/Heading'
import Link from '@elements/Link'
import { ContainerMaxWidth } from '@styles/Global'
import { useBreakpoint } from '@hooks/useBreakpoint'
import useEventListener from '@hooks/useEventListener'
import {
  PostListGrid,
  PostListColumnGap,
  PostListRowGap,
} from '@mixins/MixinsPostList'

export const FeaturedPosts = styled.section`
  ${ContainerMaxWidth}
  border-top: ${tokens.get('border')};

  .feature-posts-inner {
    ${PostListGrid}
    ${PostListColumnGap}
    ${PostListRowGap}
  }

  .content-container {
    padding-top: ${spacing(1)};
    width: 100%;
    max-width: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    .heading {
      flex: 0 1 auto;
      margin-bottom: 0;
    }

    .content {
      margin-top: ${spacing(2)};
    }

    .link {
      display: inline-flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;

      svg {
        margin-left: ${spacing(1.5)};
        width: ${spacing(3)};
      }
    }
  }

  .content {
    order: 1;
  }
`

const getNumberOfPosts = (breakpoint) => {
  switch (breakpoint) {
    case 'xs':
      return 1
    case 's':
      return 3
    default:
      return 4
  }
}

export default ({
  posts: initialPosts,
  textContent: { heading, content, link, selectedFields },
  headingSize,
  isFlex,
  containerWidth = 'wide',
}) => {
  const Breakpoint = useCallback(useBreakpoint(), [])
  const [numOfPost, setNumOfPost] = useState(
    getNumberOfPosts(Breakpoint ? Breakpoint.current : 'l')
  )
  const posts = numOfPost < 0 ? initialPosts : initialPosts.slice(0, numOfPost)

  useEventListener(
    'breakpoint', // event to listen to
    ({ detail: { old: oldBreak, new: newBreak } }) => {
      const newNumOfPosts = getNumberOfPosts(newBreak)

      if (newNumOfPosts !== numOfPost) {
        setNumOfPost(newNumOfPosts)
      }
    }
  )

  return (
    <ComponentContainer size={containerWidth} isFlex={isFlex}>
      <FeaturedPosts>
        {selectedFields && (
          <ComponentContent className="content-container">
            <Heading
              heading={heading}
              headingSize={headingSize}
              selectedFields={selectedFields}
            />
            <TextContent
              className="content"
              contentAlign="left"
              content={content}
              selectedFields={selectedFields}
            />
            <Link
              className="link"
              link={link}
              selectedFields={selectedFields}
              linkStyle="button"
              hasArrow
              isToggle
            />
          </ComponentContent>
        )}
        <div className="feature-posts-inner">
          {posts &&
            posts.map(
              (
                {
                  id,
                  uri,
                  topCategory,
                  title,
                  eventDetails: { eventDate },
                  contentPost: { featuredImage: image },
                  ...post
                },
                index
              ) => {
                const { color, name: category } = topCategory
                return (
                  <Teaser
                    key={id}
                    uri={uri}
                    color={color}
                    category={category}
                    title={title}
                    date={eventDate}
                    image={image}
                    type={index === 0 ? 'primary' : 'default'}
                    className="teaser"
                  />
                )
              }
            )}
        </div>
      </FeaturedPosts>
    </ComponentContainer>
  )
}
