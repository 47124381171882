import React from 'react'
import { graphql } from 'gatsby'
import { formatTemplateData } from '@utils/templates'
import Container from '@layout/Container'
import FeaturedPosts from '@components/FeaturedPosts'
import MediaGridWithText from '@components/MediaGrid/MediaGridWithText'
import FormatIconBanner from '@elements/FormatIconBanner'
import TextBannerWithColumns from '@components/TextBanner/TextBannerWithColumns'
import MediaWithText from '@components/MediaWithText'

const HomePage = ({ data }) => {
  const {
    wpPage,
    allWpPost: { nodes: recentPosts },
  } = data
  const { title, seo, uri } = wpPage
  seo.path = uri
  const {
    awards,
    textBanner,
    featuredPosts,
    mediaGrid,
    heroContent: heroFields,
    heroBeers,
  } = wpPage.templateHomepage

  heroFields.beers = heroBeers

  const template = {
    activeSlug: '/',
    title,
    heroFields,
    designations: ['isHomePage'],
    heroType: 'home',
    theme: 'light',
    menuTheme: 'dark',
    seo,
  }

  const { posts } = featuredPosts
  const ids = posts.map(({ id }) => id)

  if (posts.length < 4) {
    posts.push(...recentPosts.filter((post) => !ids.includes(post.id)))
  }

  const beerNames = textBanner.bannerBeers.map(
    ({ title: beerName }) => beerName
  )

  return (
    <Container template={formatTemplateData(template)}>
      <TextBannerWithColumns
        bannerWords={beerNames}
        textContent={textBanner.textContent}
        media={textBanner.media}
        headingSize="shortLarge"
      />
      <MediaWithText
        containerWidth="wide"
       
        {...awards}
        headingSize="shortLarge"
      />
      <FeaturedPosts
        posts={posts}
        bannerWords={beerNames}
        textContent={featuredPosts.textContent}
        headingSize="shortLarge"
      />
      <MediaGridWithText
        containerWidth="wide"
        mediaItems={mediaGrid.mediaItems}
        textContent={mediaGrid.textContent}
        gridType="image"
        headingSize="shortLarge"
      />
      <FormatIconBanner cropBottom isStatic />
    </Container>
  )
}

export default HomePage

export const pageQuery = graphql`
  query HomePage($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      uri
      templateHomepage {
        heroBeers {
          altText
          localFile {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 512, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        heroContent {
          textContent {
            selectedFields
            heading
            content
            link {
              type
              text
              data {
                title
                url
                target
              }
            }
          }
          image {
            localFile {
              childImageSharp {
                # TODO: Alter sizing
                fluid(maxWidth: 1920, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        awards {
          textContent {
            selectedFields
            heading
            content
            link {
              type
              text
              data {
                title
                url
                target
              }
            }
          }
          media {
            videoUrl
            mediaType
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              mediaDetails {
                width
                height
              }
            }
          }
        }
        textBanner {
          bannerBeers {
            ... on WpBeer {
              title
            }
          }
          textContent {
            selectedFields
            heading
            content
            link {
              type
              text
              data {
                title
                url
                target
              }
            }
          }
          media {
            videoUrl
            mediaType
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              mediaDetails {
                width
                height
              }
            }
          }
        }
        mediaGrid {
          mediaItems {
            media {
              videoUrl
              mediaType
              ratio
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1310, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
                mediaDetails {
                  width
                  height
                }
              }
            }
          }
          textContent {
            selectedFields
            heading
            content
            link {
              type
              text
              data {
                title
                url
                target
              }
            }
          }
        }
        featuredPosts {
          posts {
            ...TeaserPostFields
          }
          textContent {
            selectedFields
            heading
            content
            link {
              type
              text
              data {
                title
                url
                target
              }
            }
          }
        }
      }
      seo {
        title
        twitterTitle
        twitterDescription
        twitterImage {
          description
          localFile {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
        opengraphTitle
        opengraphImage {
          localFile {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
        opengraphDescription
        metaRobotsNoindex
        metaRobotsNofollow
        metaDesc
      }
    }
    allWpPost(sort: { fields: date, order: DESC }, limit: 4) {
      nodes {
        ...TeaserPostFields
      }
    }
  }
`
