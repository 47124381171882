import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { ThemeContext } from '@layout/Layout'
import { useBottleBanner } from '@hooks/useBottleBanner'
import { useAnimation, useMotionValue, motion } from 'framer-motion'
import { ScrollingText } from '@hooks/useScrollingText'

export const FormatIconContainer = styled.div`
  width: 100%;
  overflow: hidden;

  > div {
    width: 300%;
    display: block !important;
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }
`

export const FormatIconBanner = styled(motion.div)`
  width: 100%;
  height: 4rem;
  background: url(${(props) => props.beerBanner.localFile.publicURL}) repeat-x
    center top;
  background-size: auto
    ${styledMap('cropBottom', {
      true: `calc(100% + ${spacing(2)})`,
      default: '100%',
    })};
  ${styledMap('theme', {
    dark: css`
      filter: invert();
    `,
    default: '',
  })};

  ${media.greaterThan('s')`
    height: 7rem;
  `}
`

export default ({ className, cropBottom, isStatic }) => {
  const beerBanner = useBottleBanner()
  const { theme } = useContext(ThemeContext)
  return (
    <FormatIconContainer>
      {isStatic ? (
        <FormatIconBanner
          className={className}
          cropBottom={cropBottom}
          theme={theme}
          beerBanner={beerBanner}
        />
      ) : (
        <ScrollingText index={0} direction="left" isSingle from="0%" to="-50%">
          <FormatIconBanner
            className={className}
            cropBottom={cropBottom}
            theme={theme}
            beerBanner={beerBanner}
          />
        </ScrollingText>
      )}
    </FormatIconContainer>
  )
}
