import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing } from '@tokens'
import {
  TeaserBackgroundStyles,
  TeaserGrid,
  TeaserColumnGap,
} from '@mixins/MixinsTeaser'

export const TeaserContent = styled.div`
  ${styledMap('type', {
    primary: css`
      position: relative;
      z-index: ${tokens.z('content')};
      ${TeaserBackgroundStyles};
      grid-column: 1 / 7;
      grid-row: 2;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      min-height: 9.75rem;
      grid-column-end: span 6;
      max-width: calc(100% - 3.25rem);

      ${media.greaterThan('xs')`
        max-width: calc(100% - 2.75rem);
      `}

      ${media.greaterThan('520px')`
        max-width: ${tokens.get('magicNumbers.teaserMobile')};
      `}

      ${media.greaterThan('s')`
        margin-left: 0;
        grid-column: 4 / -1;
        max-width: none;
      `}

      ${media.greaterThan('m')`
        grid-column: 5 / -1;
      `}
    `,
    default: css`
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;
      width: 100%;
    `,
  })};
`

export default ({ className, children, type, color }) => {
  return (
    <TeaserContent className={className} type={type} color={color}>
      {children}
    </TeaserContent>
  )
}
