import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { spacing, media, box, tokens } from '@tokens'

export const TeaserBorders = css`
  border-top-left-radius: ${spacing(2)};
  border-top-right-radius: ${spacing(2)};
  border-bottom-left-radius: ${spacing(2)};
`

export const TeaserBackgroundColors = css`
  background-color: ${styledMap('color', {
    grey: tokens.color('teaser.grey'),
    green: tokens.color('teaser.green'),
    blue: tokens.color('teaser.blue'),
    yellow: tokens.color('teaser.yellow'),
  })};
  transition: background-color 0.1s linear;

  .teaser--primary:hover &,
  &:hover {
    background-color: ${styledMap('color', {
      grey: tokens.color('teaser.hover.grey'),
      green: tokens.color('teaser.hover.green'),
      blue: tokens.color('teaser.hover.blue'),
      yellow: tokens.color('teaser.hover.yellow'),
    })};
  }
`

export const TeaserBackgroundStyles = css`
  padding: ${spacing(3)};
  ${TeaserBackgroundColors}
  ${TeaserBorders}
`

export const TeaserGrid = css`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
`

export const TeaserColumnGap = css`
  grid-column-gap: ${spacing(3)};

  ${media.greaterThan('m')`
    grid-column-gap: ${spacing(4)};
  `}

  ${media.greaterThan('l')`
    grid-column-gap: ${spacing(6)};
  `}
`

export const TeaserRowGap = css`
  grid-row-gap: ${spacing(3)};

  ${media.greaterThan('m')`
    grid-row-gap: ${spacing(4)};
  `}

  ${media.greaterThan('l')`
    grid-row-gap: ${spacing(6)};
  `}
`
