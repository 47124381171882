import { css } from 'styled-components'
import { media, tokens, spacing } from '@tokens'

export const PostListGrid = css`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
`

export const PostListColumnGap = css`
  grid-column-gap: ${spacing(3)};

  ${media.greaterThan('m')`
    grid-column-gap: ${spacing(4)};
  `}

  ${media.greaterThan('l')`
    grid-column-gap: ${spacing(6)};
  `}
`

export const PostListRowGap = css`
  grid-row-gap: ${spacing(3)};

  ${media.greaterThan('m')`
    grid-row-gap: ${spacing(4)};
  `}

  ${media.greaterThan('l')`
    grid-row-gap: ${spacing(6)};
  `}
`
