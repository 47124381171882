import React, { useEffect, useState } from 'react'
import { useAnimation, useMotionValue, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import styled, { css } from 'styled-components'
import { tokens, media, spacing, box } from '@tokens'

export const ScrollingText = ({
  className,
  index,
  direction = 'left',
  duration = 120,
  from,
  to,
  isSingle,
  ease = 'linear',
  children,
}) => {
  const controls = useAnimation()
  const initial = {
    x: from || direction === 'left' ? (index === 2 ? '-25%' : '0%') : `-50%`,
  }
  const x = useMotionValue(
    to || direction === 'left' ? (index === 2 ? '-75%' : '-50%') : '0%'
  )

  const [ref, inView] = useInView({
    threshold: 0,
  })

  const resetAnimation = () => {
    controls.set({
      x: initial.x,
    })
  }

  const playAnimation = () => {
    controls.start({
      x: x.get(),
      transition: { duration, ease },
    })
  }

  useEffect(() => {
    resetAnimation()
    playAnimation()
  }, [])

  // TODO There is a weird rendering bug in firefox
  // If the animiation is started and stopped it causes
  // A block of text to be rendered overtop of the scrolling text.
  useEffect(() => {
    if (inView) {
      playAnimation()
    } else {
      controls.stop()
    }
  }, [inView])

  const restartAnimation = () => {
    resetAnimation()
    playAnimation()
  }

  return (
    <motion.div
      ref={ref}
      initial={initial}
      animate={controls}
      onAnimationComplete={restartAnimation}
      style={{
        display: 'inline-block',
        rotation: 0.01,
      }}
    >
      {children}
      {!isSingle && children}
    </motion.div>
  )
}

export default ScrollingText
