import React from 'react'
import styled from 'styled-components'
import { tokens, media, box } from '@tokens'
import ComponentContent from '@blocks/ComponentContent'
import ComponentContainer from '@layout/ComponentContainer'
import MediaItem from '@blocks/MediaItem'

export const MediaWithText = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-left: auto;
  margin-right: auto;
  max-width: ${tokens.get('maxWidth.tiny')};

  ${media.greaterThan('s')`
    ${box({
        spacing: 'y',
        multiplier: 2,
        type: 'margin',
    })};
    display: flex;
 `}
 
 ${media.greaterThan('m')`
    flex-flow: row nowrap;
    max-width: none;
    gap: 4rem;
    
  `}

  .media-w-text__content {
    flex: 0 1 50%;
    max-width: 22rem;
    
    ${media.greaterThan('s')`
        max-width: none;
    `}
    
    ${media.greaterThan('m')`
        order: -1;
        max-width: none;
    `}
  }

  .media-w-text__media {
    width: 100%;
    
    max-width: 22rem;
    
    ${media.greaterThan('s')`
        max-width: none;
    `}
    
    ${media.greaterThan('m')`
        width: unset;
        flex: 0 1 50%
    `}
  }
`

export default ({
  media,
  className,
  containerWidth,
  textContent,
  headingSize,
  isFlex,
}) => {
  return (
    <ComponentContainer size={containerWidth} isFlex={isFlex}>
      <MediaWithText className={className}>
      <div className='media-w-text__media'>
            <MediaItem media={media} />
        </div>
        <div className="media-w-text__content">
          <ComponentContent
            className="container-content"
            hasCTA
            textContent={textContent}
            size="tiny"
            contentAlign="left"
            textSize='large'
            headingSize={headingSize}
          />
        </div>
  
      </MediaWithText>
    </ComponentContainer>
  )
}
