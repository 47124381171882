import { useStaticQuery, graphql } from 'gatsby'
// No variables permitted in static Queries ... YET!
export const useBottleBanner = () => {
  const {
    wp: {
      themeDefaults: {
        contentThemeDefaults: { bottleBanner },
      },
    },
  } = useStaticQuery(
    graphql`
      query BeerBanner {
        wp {
          themeDefaults {
            contentThemeDefaults {
              bottleBanner {
                localFile {
                  absolutePath
                  publicURL
                  childImageSharp {
                    fixed(height: 112) {
                      ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                  }
                }
                mediaDetails {
                  width
                  height
                }
              }
            }
          }
        }
      }
    `
  )

  return bottleBanner
}
