import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import Image from '@elements/Image'
import MagicItem from '@hooks/useMagic'
import { TeaserBorders } from '@mixins/MixinsTeaser'

export const TeaserImage = styled.div`
  ${TeaserBorders}
  position: relative;
  z-index: ${tokens.z('base')};
  overflow: hidden;
  grid-column: 1 / 6;
  grid-row: 1 / 4;

  ${media.lessThan('s')`
    ${box({
      spacing: 'x',
      multiplier: -1,
      type: 'margin',
    })};
    margin-bottom: ${spacing(-4)};
    padding-left: ${spacing()};
    padding-right: ${spacing()};
  `}

  .gatsby-image-wrapper,
  .teaser-image,
  picture,
  > div,
  > div > div,
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;

    ${media.lessThan('m')`
      /* Gatsby Image */
      object-position: 20% 50% !important;
    `}
  }

  &:after {
    ${media.greaterThan('s')`
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      right: 0%;
      left: auto;
      width: 100%;
      display: block;
      content: '';
      z-index: ${tokens.z('aboveContent')};
      background: linear-gradient(
        to left,
        ${tokens.color('gradient.hero.light.from')},
        ${tokens.color('gradient.hero.light.to')}
      );
    `}

    ${media.greaterThan('m')`
      width: 75%;
    `}
  }
`

export default ({ className, source }) => {
  return (
    <TeaserImage className={className}>
      <MagicItem>
        <Image source={source} className="teaser-image" />
      </MagicItem>
    </TeaserImage>
  )
}
