import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { getBoxSpacingForProperty } from '@tokens/spacing'

export const Columns = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${spacing(6)};
  grid-row-gap: ${spacing(4)};

  ${media.greaterThan('s')`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.greaterThan('m')`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${styledMap('short', {
    true: css`
      ${media.greaterThan('s')`
        grid-row-gap: ${spacing(4)};
      `}
    `,
    default: css`
      ${media.greaterThan('s')`
        ${getBoxSpacingForProperty('y', {
          property: 'grid-row-gap',
          multiplier: 0.5,
        })};
      `}
    `,
  })};
`

export default ({ children, className, short }) => {
  return (
    <Columns className={className} short={short}>
      {children}
    </Columns>
  )
}
