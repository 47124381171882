import React, { useCallback, useLayoutEffect, useState } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { FontStyleH1, H1Sizes } from '@styles/Typography'
import ComponentContainer from '@layout/ComponentContainer'
import ComponentContent from '@blocks/ComponentContent'
import MediaItem from '@blocks/MediaItem'
import Columns from '@blocks/Columns'
import TextBanner from '@components/TextBanner'
import { useBreakpoint } from '@hooks/useBreakpoint'
import useEventListener from '@hooks/useEventListener'

export const TextBannerWithColumns = styled.section`
  ${box({
    spacing: 'x',
  })};

  .columns {
    position: relative;
    z-index: ${tokens.z('content')};
    width: 100%;
    max-width: ${tokens.get('maxWidth.wide')};
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;

    ${media.greaterThan('s')`
      flex-flow: row nowrap;
    `}
  }

  .column {
    flex: 1 1 100%;

    ${media.greaterThan('s')`
      flex: 0 1 auto;
    `}

    ${media.greaterThan('m')`
      flex: 1 1 50%;
    `}
  }

  .column--media {
    max-width: 60%;

    ${media.greaterThan('s')`
      margin-right: ${spacing(6)};
    `}

    svg,
    .image {
      display: block;
      width: 15rem;
      margin-right: auto;
      margin-left: auto;
      max-width: 100%;

      ${media.greaterThan('s')`
        margin-right: 0;
      `}

      ${media.greaterThan('m')`
        width: 18.75rem;
      `}
    }
  }

  .column--text {
    ${media.lessThan('s')`
      max-width: 22rem;
      margin-right: auto;
      margin-left: auto;
    `}

    &::before {
      padding-top: ${spacing(4)};
      content: '';
      width: 100%;
      display: block;

      ${media.greaterThan('s')`
        ${H1Sizes}
        margin-top: 1.5em;
      `}
    }
  }
`

export default ({
  className = '',
  bannerText,
  bannerWords,
  media,
  textContent,
  headingSize,
}) => {
  const Breakpoint = useCallback(useBreakpoint(), [])
  const [isMobile, setIsMobile] = useState(false)

  useLayoutEffect(() => {
    if (Breakpoint.max('s')) {
      setIsMobile(true)
    }
  }, [])

  useEventListener(
    'breakpoint', // event to listen to
    ({ detail: { old: oldBreak, new: newBreak } }) => {
      if (newBreak === 's' && oldBreak === 'm') {
        setIsMobile(true)
      } else if (newBreak === 'm' && oldBreak === 's') {
        setIsMobile(false)
      }
    }
  )

  return (
    <>
      <TextBanner
        bannerText={bannerText}
        bannerWords={bannerWords}
        duration="30"
      />
      <TextBannerWithColumns>
        <div className="columns">
          <div className="column column--media">
            {media && <MediaItem media={media} magicType="none" />}
          </div>
          <div className="column column--text">
            {textContent && (
              <ComponentContent
                className="content"
                hasCTA
                textContent={textContent}
                size="tiny"
                contentAlign="left"
                headingSize={headingSize}
                textSize={!isMobile ? 'large' : ''}
              />
            )}
          </div>
        </div>
      </TextBannerWithColumns>
    </>
  )
}
