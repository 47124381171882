import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing } from '@tokens'
import { LabelStyles } from '@styles/Typography'

export const TeaserCategory = styled.div`
  ${LabelStyles}
  margin-bottom: ${spacing()};

  ${styledMap('type', {
    primary: css`
      margin-bottom: 0;
    `,
    default: '',
  })};
`

export default ({ className, children, type }) => {
  return (
    <TeaserCategory className={className} type={type}>
      {children}
    </TeaserCategory>
  )
}
