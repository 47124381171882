import React, { useCallback, useLayoutEffect, useState } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import ComponentContent from '@blocks/ComponentContent'
import ComponentContainer from '@layout/ComponentContainer'
import MediaItem from '@blocks/MediaItem'
import { useBreakpoint } from '@hooks/useBreakpoint'
import useEventListener from '@hooks/useEventListener'

export const MediaGridWithText = styled.div`
  ${media.greaterThan('m')`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto auto auto;
    grid-column-gap: ${spacing(6)};
  `}

  .grid-item--0 {
    margin-bottom: -15%;

    ${media.greaterThan('m')`
      grid-column: 1 / 9;
      grid-row-start: 1;
      margin-bottom: -25%;
    `}
  }

  .grid-item--1 {
    grid-column: 7 / -1;
    grid-row: 2 / -1;
  }

  .grid-item--content {
    grid-column: 1 / 7;
    grid-row: 2 / -1;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
  }
`

export default ({
  mediaItems: initialMediaItems,
  className,
  containerWidth,
  textContent,
  headingSize,
  isFlex,
  gridType: initialGridType,
}) => {
  const Breakpoint = useCallback(useBreakpoint(), [])
  const [isMobile, setIsMobile] = useState(false)
  const gridType = initialGridType || initialMediaItems[0].media.mediaType
  const mediaItems = isMobile ? [initialMediaItems[0]] : initialMediaItems
  useLayoutEffect(() => {
    if (Breakpoint.max('s')) {
      setIsMobile(true)
    }
  }, [])

  useEventListener(
    'breakpoint', // event to listen to
    ({ detail: { old: oldBreak, new: newBreak } }) => {
      if (newBreak === 's' && oldBreak === 'm') {
        setIsMobile(true)
      } else if (newBreak === 'm' && oldBreak === 's') {
        setIsMobile(false)
      }
    }
  )

  return (
    <ComponentContainer size={containerWidth} isFlex={isFlex}>
      <MediaGridWithText className={className} isMobile={isMobile}>
        {mediaItems &&
          mediaItems.map(({ media }, index) => {
            return (
              <div key={index} className={`grid-item grid-item--${index}`}>
                <MediaItem media={media} />
              </div>
            )
          })}
        <div className="grid-item grid-item--content">
          <ComponentContent
            className="container-content"
            hasCTA
            textContent={textContent}
            size="tiny"
            contentAlign="left"
            textSize={!isMobile ? 'large' : ''}
            headingSize={headingSize}
          />
        </div>
      </MediaGridWithText>
    </ComponentContainer>
  )
}
