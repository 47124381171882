import React, { useCallback, useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing } from '@tokens'
import {
  TeaserBackgroundStyles,
  TeaserGrid,
  TeaserColumnGap,
} from '@mixins/MixinsTeaser'
import Link from '@elements/Link'
import { useBreakpoint } from '@hooks/useBreakpoint'
import useEventListener from '@hooks/useEventListener'
import CallToActionLinkText from '@elements/CallToActionLinkText'
import { motion } from 'framer-motion'
import { MagicItem } from '@hooks/useMagic'
import TeaserImage from './TeaserImage'
import TeaserContent from './TeaserContent'
import TeaserHeading from './TeaserHeading'
import TeaserCategory from './TeaserCategory'
import TeaserFooter from './TeaserFooter'

export const Teaser = styled(motion.div)`
  transform-origin: '50% 50%';
  width: 100%;
  height: auto;
  color: ${tokens.color('text')};

  ${styledMap('type', {
    primary: css`
      a {
        position: relative;
        width: 100%;

        ${media.greaterThan('s')`
          ${TeaserGrid}
          ${TeaserColumnGap}
          grid-template-rows: auto auto ${spacing(4)};
        `}
      }
      grid-column: 1 / 7;
    `,
    default: css`
      ${TeaserBackgroundStyles}
      max-width: calc(100% - 3.25rem);
      grid-column-end: span 6;

      ${media.greaterThan('xs')`
        max-width: calc(100% - 2.75rem);
      `}


      ${media.greaterThan('520px')`
        max-width: ${tokens.get('magicNumbers.teaserMobile')};
      `}

      ${media.greaterThan('s')`
        grid-column-end: span 3;
      `}

      ${media.greaterThan('m')`
        grid-column-end: span 2;
      `}
    `,
  })};

  > a {
    height: 100%;
    width: 100%;
  }
`

export default ({
  uri,
  link,
  color,
  type,
  category: initialCategory,
  title,
  date,
  image,
  className = '',
}) => {
  const classes = `teaser--${type} ${className}`
  const [isHovered, setIsHovered] = useState(false)
  const category = !['Stories', 'Events'].includes(initialCategory)
    ? initialCategory
    : initialCategory === 'Stories'
    ? 'Story'
    : 'Event'
  return (
    <Teaser
      color={color}
      className={classes}
      type={type}
      whileHover="hovered"
      onHoverStart={() => {
        if (!isHovered) {
          setIsHovered(true)
        }
      }}
      onHoverEnd={() => {
        if (isHovered) {
          setIsHovered(false)
        }
      }}
    >
      <Link link={link || { url: uri, type: 'url' }} linkStyle="none">
        {type === 'primary' && <TeaserHeading type={type} title={title} />}
        {image && type === 'primary' && (
          <TeaserImage source={image} type={type} />
        )}
        <TeaserContent type={type} color={color}>
          {category && <TeaserCategory type={type}>{category}</TeaserCategory>}
          {type !== 'primary' && (
            <TeaserHeading
              headingSize="shortLarge"
              textCase="none"
              type={type}
              title={title}
            />
          )}
          <TeaserFooter type={type}>
            <CallToActionLinkText
              linkText={link ? link.title : undefined}
              date={date}
              isHovered={isHovered}
              theme="light"
            />
          </TeaserFooter>
        </TeaserContent>
      </Link>
    </Teaser>
  )
}

Teaser.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  category: PropTypes.string,
  date: PropTypes.string,
  style: PropTypes.string,
  image: PropTypes.object,
}
