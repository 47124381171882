import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { FontStyleH1, H1Sizes } from '@styles/Typography'
import ComponentContainer from '@layout/ComponentContainer'
import ComponentContent from '@blocks/ComponentContent'
import Caption from '@elements/Caption'
import Image from '@elements/Image'
import ScrollingText from '@hooks/useScrollingText'

export const TextBanner = styled.section`
  ${box({
    spacing: 'top',
    type: 'margin',
  })};
  ${H1Sizes}
  position: relative;
  width: 100%;

  &::after {
    content: '';
    display: block;
    margin-bottom: -1.5em;
  }

  > .inner {
    position: relative;
  }

  .banner {
    ${FontStyleH1}
    width: 100%;
    overflow: hidden;
  }

  .banner-text {
    position: relative;
    color: ${tokens.brand('light')};
    text-transform: uppercase;
    width: auto;
    white-space: nowrap;
  }

  .banner-text--3 {
    text-indent: -5em;
  }
`

export default ({
  className = '',
  bannerText,
  bannerWords,
  bannerTextRows = 3,
  children,
  designations,
  duration = 8,
  isFlex,
}) => {
  const bannerRows = []
  if (bannerText) {
    for (let i = 0; i < bannerTextRows; i++) {
      bannerRows.push(bannerText)
    }
  }

  if (bannerWords) {
    for (let i = 0; i < bannerTextRows; i++) {
      const key = 3 * i
      bannerRows.push(
        bannerWords.slice(key, key + 3).join('.&nbsp;') + '.&nbsp;'
      )
    }
  }

  return (
    <TextBanner
      className={className}
      designations={designations}
      isFlex={isFlex}
    >
      {bannerRows.length && (
        <div className="banner">
          {bannerRows.map((bannerRow, index) => {
            return (
              <ScrollingText
                key={index}
                index={index}
                direction={index === 1 ? 'right' : 'left'}
                duration={index === 2 && duration ? duration * 1.5 : duration}
              >
                <span
                  className={`banner-text banner-text--${index + 1}`}
                  dangerouslySetInnerHTML={{ __html: bannerRow }}
                />
              </ScrollingText>
            )
          })}
        </div>
      )}
      {children}
    </TextBanner>
  )
}
