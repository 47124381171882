import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing } from '@tokens'
import { H1SizesShort, H2, BlogH1Sizes, Heading } from '@styles/Typography'

export const TeaserHeading = styled(H2)`
  ${Heading};
  ${H1SizesShort};
  margin-bottom: ${spacing(4)};
  position: relative;
  z-index: ${tokens.z('content')};

  ${styledMap('type', {
    primary: css`
      ${BlogH1Sizes};
      margin-bottom: -0.5em;
      grid-column: 1 / -1;
      grid-row: 1;
      max-width: ${tokens.get('magicNumbers.teaserMobile')};

      ${media.greaterThan('s')`
        max-width: none;
        padding-top: ${spacing(6)};
        margin-bottom: ${spacing(6)};
        padding-left: ${spacing(3)};
        grid-column: 3 / -1;
        padding-left: 0;
        margin-bottom: ${spacing(10)};
      `}

      ${media.greaterThan('m')`
        grid-column: 3 / -1;
      `}
    `,
    default: '',
  })};
`

export default ({ className, headingSize, textCase, type, title }) => {
  return (
    <TeaserHeading
      className={`heading ${className}`}
      headingSize={headingSize}
      type={type}
      textCase={textCase}
      dangerouslySetInnerHTML={{ __html: title }}
    />
  )
}
